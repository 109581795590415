"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.controllerMap = void 0;
const Endpoints_1 = require("../Endpoints");
exports.controllerMap = {
    GenerateClaimCode: Endpoints_1.GenerateClaimCode,
    ClaimEducator: Endpoints_1.ClaimEducator,
    RecordExternalRedirect: Endpoints_1.RecordExternalRedirect,
    ZapierMarkBookingAsPaid: Endpoints_1.ZapierMarkBookingAsPaid,
    XeroGetOAuthUrl: Endpoints_1.XeroGetOAuthUrl,
    XeroCompleteConnection: Endpoints_1.XeroCompleteConnection,
    GetAuthHello: Endpoints_1.GetAuthHello,
    AuthIam: Endpoints_1.AuthIam,
    Login: Endpoints_1.Login,
    LoginWithPassword: Endpoints_1.LoginWithPassword,
    LoginViaSocial: Endpoints_1.LoginViaSocial,
    Logout: Endpoints_1.Logout,
    MagicLogin: Endpoints_1.MagicLogin,
    ClaimMagicLogin: Endpoints_1.ClaimMagicLogin,
    ClaimSsoCode: Endpoints_1.ClaimSsoCode,
    RefreshTokens: Endpoints_1.RefreshTokens,
    ChangePassword: Endpoints_1.ChangePassword,
    ForgotPassword: Endpoints_1.ForgotPassword,
    CompletePasswordReset: Endpoints_1.CompletePasswordReset,
    SetMagicUserPassword: Endpoints_1.SetMagicUserPassword,
    SetNewPassword: Endpoints_1.SetNewPassword,
    CreateUser: Endpoints_1.CreateUser,
    UpdateUserDetails: Endpoints_1.UpdateUserDetails,
    VerifyUser: Endpoints_1.VerifyUser,
    ChangeBookingStudent: Endpoints_1.ChangeBookingStudent,
    GetBookingDetailsForCourse: Endpoints_1.GetBookingDetailsForCourse,
    CalendarSyncListAccounts: Endpoints_1.CalendarSyncListAccounts,
    CalendarSyncAddAccount: Endpoints_1.CalendarSyncAddAccount,
    CalendarSyncUpdateAccount: Endpoints_1.CalendarSyncUpdateAccount,
    CalendarSyncArchiveAccount: Endpoints_1.CalendarSyncArchiveAccount,
    CalendarSyncListExternalAccountCalendars: Endpoints_1.CalendarSyncListExternalAccountCalendars,
    CalendarSyncAddCalendar: Endpoints_1.CalendarSyncAddCalendar,
    CalendarSyncListAccountCalendars: Endpoints_1.CalendarSyncListAccountCalendars,
    CalendarSyncReauthenticateAccount: Endpoints_1.CalendarSyncReauthenticateAccount,
    CalendarSyncArchiveCalendar: Endpoints_1.CalendarSyncArchiveCalendar,
    CalendarSyncListCalendars: Endpoints_1.CalendarSyncListCalendars,
    GetSuggestedCategories: Endpoints_1.GetSuggestedCategories,
    CalendarSyncGetEducatorDefaultCalendar: Endpoints_1.CalendarSyncGetEducatorDefaultCalendar,
    CalendarSyncSetEducatorDefaultCalendar: Endpoints_1.CalendarSyncSetEducatorDefaultCalendar,
    SubmitCheckoutResponse: Endpoints_1.SubmitCheckoutResponse,
    InitialiseBookingCheckout: Endpoints_1.InitialiseBookingCheckout,
    InitialiseOrderCheckout: Endpoints_1.InitialiseOrderCheckout,
    InitialiseRequestCheckout: Endpoints_1.InitialiseRequestCheckout,
    InitialiseBookingApplicationCheckout: Endpoints_1.InitialiseBookingApplicationCheckout,
    InitialiseGroupBookingRequestCheckout: Endpoints_1.InitialiseGroupBookingRequestCheckout,
    InitialiseDateBookingRequestCheckout: Endpoints_1.InitialiseDateBookingRequestCheckout,
    CheckoutPaymentOptions: Endpoints_1.CheckoutPaymentOptions,
    DeleteContact: Endpoints_1.DeleteContact,
    ChangeContactEmail: Endpoints_1.ChangeContactEmail,
    GetChangeContactEmailConsequences: Endpoints_1.GetChangeContactEmailConsequences,
    GetCourseAvailableDates: Endpoints_1.GetCourseAvailableDates,
    GetCourseAvailableTimeSlots: Endpoints_1.GetCourseAvailableTimeSlots,
    GetCoursePaymentOptions: Endpoints_1.GetCoursePaymentOptions,
    GetCourseDescription: Endpoints_1.GetCourseDescription,
    CoursesCreateBookingsImport: Endpoints_1.CoursesCreateBookingsImport,
    CoursesConfigureBookingsImport: Endpoints_1.CoursesConfigureBookingsImport,
    CoursesCreateOrdersImport: Endpoints_1.CoursesCreateOrdersImport,
    CoursesConfigureOrdersImport: Endpoints_1.CoursesConfigureOrdersImport,
    GetMyStudentBookingsForCourse: Endpoints_1.GetMyStudentBookingsForCourse,
    GetCourseEnrolmentsSummary: Endpoints_1.GetCourseEnrolmentsSummary,
    GetEducatorPaymentMethods: Endpoints_1.GetEducatorPaymentMethods,
    CreateEducator: Endpoints_1.CreateEducator,
    GetPublicEducator: Endpoints_1.GetPublicEducator,
    EducatorGetPlanConfigurationURL: Endpoints_1.EducatorGetPlanConfigurationURL,
    EducatorBillingStripeWebhook: Endpoints_1.EducatorBillingStripeWebhook,
    UnsubscribeEducator: Endpoints_1.UnsubscribeEducator,
    ListEducatorPermissions: Endpoints_1.ListEducatorPermissions,
    EducatorSetBrandColors: Endpoints_1.EducatorSetBrandColors,
    EducatorEnableWhitelabelling: Endpoints_1.EducatorEnableWhitelabelling,
    BulkImportGetBulkImport: Endpoints_1.BulkImportGetBulkImport,
    BulkImportListContacts: Endpoints_1.BulkImportListContacts,
    CertificateTemplatesListCertificateTemplates: Endpoints_1.CertificateTemplatesListCertificateTemplates,
    CertificateTemplatesCreateCertificateTemplate: Endpoints_1.CertificateTemplatesCreateCertificateTemplate,
    CertificateTemplatesGetCertificateTemplate: Endpoints_1.CertificateTemplatesGetCertificateTemplate,
    CertificateTemplatesUpdateCertificateTemplate: Endpoints_1.CertificateTemplatesUpdateCertificateTemplate,
    CertificateTemplatesArchiveCertificateTemplate: Endpoints_1.CertificateTemplatesArchiveCertificateTemplate,
    CertificateTemplatesDuplicateCertificateTemplate: Endpoints_1.CertificateTemplatesDuplicateCertificateTemplate,
    CertificatesGetBookingAttendanceCertificate: Endpoints_1.CertificatesGetBookingAttendanceCertificate,
    CertificatesGetOrderCompletedCertificate: Endpoints_1.CertificatesGetOrderCompletedCertificate,
    CertificatesListCertificatesForBooking: Endpoints_1.CertificatesListCertificatesForBooking,
    CertificatesIssueCertificate: Endpoints_1.CertificatesIssueCertificate,
    CertificatesRevokeCertificate: Endpoints_1.CertificatesRevokeCertificate,
    CertificatesListCertificatesForOrder: Endpoints_1.CertificatesListCertificatesForOrder,
    EmailDomainsListEmailDomains: Endpoints_1.EmailDomainsListEmailDomains,
    EmailDomainsCreateEmailDomain: Endpoints_1.EmailDomainsCreateEmailDomain,
    EmailDomainsGetDefaultSender: Endpoints_1.EmailDomainsGetDefaultSender,
    EmailDomainsSetDefaultSender: Endpoints_1.EmailDomainsSetDefaultSender,
    EmailDomainsResetDefaultSender: Endpoints_1.EmailDomainsResetDefaultSender,
    EmailDomainsDeleteEmailDomain: Endpoints_1.EmailDomainsDeleteEmailDomain,
    EmailDomainsRefreshEmailDomain: Endpoints_1.EmailDomainsRefreshEmailDomain,
    GetCourseImports: Endpoints_1.GetCourseImports,
    CreateCourseImport: Endpoints_1.CreateCourseImport,
    GetEducatorCredits: Endpoints_1.GetEducatorCredits,
    SupportEducatorResetPlan: Endpoints_1.SupportEducatorResetPlan,
    SupportEducatorMoveToCustomPlan: Endpoints_1.SupportEducatorMoveToCustomPlan,
    InternalListEducatorLimits: Endpoints_1.InternalListEducatorLimits,
    InternalOverrideEducatorLimit: Endpoints_1.InternalOverrideEducatorLimit,
    InternalDeleteEducatorLimit: Endpoints_1.InternalDeleteEducatorLimit,
    InternalListEducatorPermissions: Endpoints_1.InternalListEducatorPermissions,
    InternalSetEducatorPermission: Endpoints_1.InternalSetEducatorPermission,
    InternalDeleteEducatorPermission: Endpoints_1.InternalDeleteEducatorPermission,
    SupportRemoveAdminFromEducator: Endpoints_1.SupportRemoveAdminFromEducator,
    SupportChangeEducatorSlug: Endpoints_1.SupportChangeEducatorSlug,
    SupportHideEducator: Endpoints_1.SupportHideEducator,
    SupportRemoveFromSuppressionList: Endpoints_1.SupportRemoveFromSuppressionList,
    LimitsCanI: Endpoints_1.LimitsCanI,
    GetLocationByID: Endpoints_1.GetLocationByID,
    GetPublicLocations: Endpoints_1.GetPublicLocations,
    OrdersAndBookings: Endpoints_1.OrdersAndBookings,
    ChangeOrderStudent: Endpoints_1.ChangeOrderStudent,
    GetOrderDetails: Endpoints_1.GetOrderDetails,
    ListPermissions: Endpoints_1.ListPermissions,
    PermissionsTest: Endpoints_1.PermissionsTest,
    AcceptBookingRequest: Endpoints_1.AcceptBookingRequest,
    CaptureReview: Endpoints_1.CaptureReview,
    CaptureReviewBreakdown: Endpoints_1.CaptureReviewBreakdown,
    CaptureReviewMessage: Endpoints_1.CaptureReviewMessage,
    ListPublicEducatorReviews: Endpoints_1.ListPublicEducatorReviews,
    ArchiveReview: Endpoints_1.ArchiveReview,
    SaveScormDataModel: Endpoints_1.SaveScormDataModel,
    ScormRuntimeInitialise: Endpoints_1.ScormRuntimeInitialise,
    SearchCourses: Endpoints_1.SearchCourses,
    SearchOccupations: Endpoints_1.SearchOccupations,
    ListMySavedCourses: Endpoints_1.ListMySavedCourses,
    AddCourseToSavedCourses: Endpoints_1.AddCourseToSavedCourses,
    GetSavedCourseStatusByCourseId: Endpoints_1.GetSavedCourseStatusByCourseId,
    RemoveCourseFromSavedCourses: Endpoints_1.RemoveCourseFromSavedCourses,
    AuthenticateSocket: Endpoints_1.AuthenticateSocket,
    SubmitQuizResponse: Endpoints_1.SubmitQuizResponse,
    SubmitDraftQuizResponse: Endpoints_1.SubmitDraftQuizResponse,
    UsersIam: Endpoints_1.UsersIam,
    InviteAdmin: Endpoints_1.InviteAdmin,
    ListInvites: Endpoints_1.ListInvites,
    MigrateUser: Endpoints_1.MigrateUser,
    UserMigrationData: Endpoints_1.UserMigrationData,
    UpdateUserRole: Endpoints_1.UpdateUserRole,
    RemoveAdminFromEducator: Endpoints_1.RemoveAdminFromEducator,
    VideoConferencingListAccounts: Endpoints_1.VideoConferencingListAccounts,
    VideoConferencingAddAccount: Endpoints_1.VideoConferencingAddAccount,
    VideoConferencingUpdateAccount: Endpoints_1.VideoConferencingUpdateAccount,
    VideoConferencingArchiveAccount: Endpoints_1.VideoConferencingArchiveAccount,
    VideoConferencingReauthenticateAccount: Endpoints_1.VideoConferencingReauthenticateAccount,
};
